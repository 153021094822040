const resetInput = (input) => {
  input.classList.remove('form__input--valid', 'form__input--invalid', 'parent-success', 'filled', 'form__input--dirty');
  input.parentNode.parentNode.querySelectorAll('.form__validation-error-message').forEach((requiredText) => {
    requiredText.remove();
  });
  if (input.tagName === 'SELECT') {
    input.selectedIndex = '0';
  } else if (input.type === 'radio' || input.type === 'checkbox') {
    input.checked = false;
  } else {
    input.value = '';
  }

  const event = document.createEvent('HTMLEvents');
  event.initEvent('change', true, false);
  input.dispatchEvent(event);
};

const initializeHiddenFields = (selector) => {
  document.querySelectorAll(selector).forEach((section) => {
    section.querySelectorAll('input, select, textarea').forEach((input) => {
      input.removeAttribute('required');
      resetInput(input);
    });
  });
};

const removeButtonHandler = (event) => {
  const removeButton = event.target;
  const buttonsWrapper = removeButton.parentElement.parentElement;
  const sectionKey = buttonsWrapper.dataset.section;
  const sectionMin = parseInt(buttonsWrapper.dataset.sectionMin, 10);
  const visibleSectionsSelector = `[data-section-key=${sectionKey}]:not(.hidden)`;
  const nodes = document.querySelectorAll(visibleSectionsSelector);
  if (nodes.length > sectionMin) {
    const section = removeButton.closest('fieldset.js-section');
    section.classList.add('hidden');
    section.querySelectorAll('input, select, textarea').forEach((input) => {
      input.removeAttribute('required');
      resetInput(input);
    });
    section.querySelectorAll('.file-upload__remove-file-btn').forEach(removeFile => removeFile.click());
    const repeatableSection = removeButton.closest('section.js-wrapper-repeatable-section');
    const addButton = repeatableSection.querySelector('.js-add-btn');
    const AddButtonSection = addButton.parentElement.parentElement;
    AddButtonSection.classList.remove('hidden');
  }
};

const addButtonHandler = (event) => {
  const sectionKey = event.target.parentElement.dataset.section;
  const addButtonSection = event.target.parentElement.parentElement;
  const hiddenSectionsSelector = `[data-section-key=${sectionKey}].hidden`;
  const nodes = document.querySelectorAll(hiddenSectionsSelector);
  const section = nodes[0];
  section.classList.remove('hidden');
  section.querySelectorAll('input, select, textarea').forEach((input) => {
    if (input.dataset.required === 'true') input.setAttribute('required', 'required');
  });
  if (nodes.length === 1) addButtonSection.classList.add('hidden');
};

const setupRepetitionRemoveButton = (buttonsWrapper) => {
  const removeButton = buttonsWrapper.querySelector('.js-remove-btn');
  removeButton.addEventListener('click', removeButtonHandler);
};

const setupRepetitionAddButton = (buttonsWrapper) => {
  const addButton = buttonsWrapper.querySelector('.js-add-btn');
  const sectionKey = buttonsWrapper.dataset.section;
  const hiddenSectionsSelector = `[data-section-key=${sectionKey}].hidden`;
  const repeatableSection = buttonsWrapper.closest('section.js-wrapper-repeatable-section');
  const nodes = repeatableSection.querySelectorAll(hiddenSectionsSelector);
  if (nodes.length === 0) addButton.classList.add('hidden');
  addButton.addEventListener('click', addButtonHandler);
};

const setupRepeatableSection = (repeatableSection) => {
  repeatableSection.querySelectorAll('.js-repetition-remove-button').forEach((buttonsWrapper) => {
    const sectionKey = buttonsWrapper.dataset.section;
    const hiddenSectionsSelector = `[data-section-key=${sectionKey}].hidden`;
    initializeHiddenFields(hiddenSectionsSelector);
    setupRepetitionRemoveButton(buttonsWrapper);
  });
  repeatableSection.querySelectorAll('.js-repetition-add-button').forEach((buttonsWrapper) => {
    const sectionKey = buttonsWrapper.dataset.section;
    const hiddenSectionsSelector = `[data-section-key=${sectionKey}].hidden`;
    initializeHiddenFields(hiddenSectionsSelector);
    setupRepetitionAddButton(buttonsWrapper);
  });
};

const repeatableElement = (repeatableSections) => {
  repeatableSections.forEach(repeatableSection => setupRepeatableSection(repeatableSection));
};

const disconnectRepeatableSection = (repeatableSection) => {
  repeatableSection.querySelectorAll('.js-repetition-add-button').forEach((buttonsWrapper) => {
    const addButton = buttonsWrapper.querySelector('.js-add-btn');
    addButton.removeEventListener('click', addButtonHandler);
  });

  repeatableSection.querySelectorAll('.js-repetition-remove-button').forEach((buttonsWrapper) => {
    const addButton = buttonsWrapper.querySelector('.js-remove-btn');
    addButton.removeEventListener('click', removeButtonHandler);
  });
};

const repeatableDisconnect = (repeatableSections) => {
  repeatableSections.forEach(repeatableSection => disconnectRepeatableSection(repeatableSection));
};

export default () => {
  const repeatableSections = document.querySelectorAll('.js-enrollment-forms form .js-wrapper-repeatable-section');
  repeatableElement(repeatableSections);
  return () => {
    repeatableDisconnect(repeatableSections);
  };
};
