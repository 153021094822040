import { Controller } from '@hotwired/stimulus';

const satisfiesPasswordCharRequirements = (input) => {
  // this regex checks for uppercase, lowercase, special character and numeric value in string
  const pattern = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$');
  return pattern.test(input);
};

const isMinLength = input => input && input.length >= 8;

export default class extends Controller {
  static targets = [
    'passwordInput',
    'passwordInputPrompt',
  ]

  connect () {
    if (document.querySelector('.password-input-group .password .form__validation-error-message.hidden')) {
      this.validatePassword();
    }
  }

  validatePassword () {
    const passwordValid = isMinLength(this.passwordInputTarget.value)
      && satisfiesPasswordCharRequirements(this.passwordInputTarget.value);
    if (passwordValid) {
      this.passwordInputTarget.classList.remove('form__input--dirty');
      this.passwordInputTarget.classList.remove('form__input--invalid');
      this.passwordInputPromptTarget.classList.add('hidden');
    } else {
      this.passwordInputTarget.classList.add('form__input--dirty');
      this.passwordInputTarget.classList.add('form__input--invalid');
      this.passwordInputPromptTarget.classList.remove('hidden');
    }
  }
}
