// eslint-disable-next-line import/prefer-default-export
export const getSelectedRadioValue = (radios) => {
  const selected = [].find.call(
    radios,
    radio => radio.checked,
  );
  return (selected && selected.value) || null;
};

export const getAncestorWithClass = (el, className) => {
  let searchedElement = el.parentElement;
  while (searchedElement && !searchedElement.classList.contains(className)) {
    searchedElement = searchedElement.parentElement;
  }
  return searchedElement;
};
