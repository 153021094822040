const create = () => {
  const bootstrapper = {};
  const data = document.head.dataset || {};

  Object.keys(data).forEach((key) => {
    const val = data[key];
    if (val.length > 0) {
      try {
        bootstrapper[key] = JSON.parse(val);
      } catch (_) {
        bootstrapper[key] = val;
      }
    }
  });

  return bootstrapper;
};

const bootstrapper = create();

export default bootstrapper;
