// controllers/enrollment/enrollment_form_controller.js
import { Controller } from '@hotwired/stimulus';
import repeatingSections from '../../modules/enrollmentForms/repeatingSections';

export default class extends Controller {
  connect () {
    this.disconnectListeners = [
      repeatingSections(),
    ];
  }

  disconnect () {
    this.disconnectListeners.forEach(disconnectListener =>
      disconnectListener(),
    );
  }
}
