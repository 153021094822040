// controllers/enrollment_engine/conditional_field_controller.js
import { Controller } from '@hotwired/stimulus';
import { getAncestorWithClass } from '../../modules/utils/form';

export default class extends Controller {
  static targets = ['source'];

  connect () {
    this.loadDefaults();
  }

  loadDefaults () {
    const selector = '[data-conditionally-displayed=true]';
    const inputs = document.querySelectorAll(selector);

    inputs.forEach((input) => {
      const wrapper = getAncestorWithClass(input, 'js-file-upload') || getAncestorWithClass(input, 'js-input-wrapper');
      this.constructor.hideInputField(input, wrapper);
    });

    document.querySelectorAll('input[type=radio]:checked').forEach((radio) => {
      this.toggleConditionalFields({ currentTarget: radio });
    });

    document.querySelectorAll('.js-input-wrapper select').forEach((select) => {
      this.toggleConditionalFields({ currentTarget: select });
    });
  }

  toggleConditionalFields ({ currentTarget: target }) {
    const currentValue = target.value;
    const targetWrapper = target.closest('fieldset.js-section');
    const selector = `[data-conditionally-displayed=true][data-display-on-other-field-id='${target.dataset.fieldId}']`;
    const inputs = targetWrapper.querySelectorAll(selector);

    inputs.forEach((input) => {
      const wrapper = getAncestorWithClass(input, 'js-file-upload') || getAncestorWithClass(input, 'js-input-wrapper');
      this.changeVisibility(currentValue, input.dataset.displayOnOtherFieldValue, input, wrapper);
    });
  }

  changeVisibility (fieldValue, otherFieldValueForDisplay, input, elementsToToggle) {
    if (fieldValue === otherFieldValueForDisplay) {
      this.constructor.displayInputField(input, elementsToToggle);
    } else {
      this.constructor.hideInputField(input, elementsToToggle);
    }
  }

  static hideInputField (input, elementsToToggle) {
    if (input.dataset.required === 'true') {
      input.removeAttribute('required');
      input.classList.remove('form__input--invalid', 'form__input--dirty');
    }
    elementsToToggle.classList.add('hidden');
  }

  static displayInputField (input, elementsToToggle) {
    if (input.dataset.required === 'true') {
      input.setAttribute('required', 'required');
    }
    elementsToToggle.classList.remove('hidden');
  }
}
